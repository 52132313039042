type projectDataModel = {
    name: string,
    title: string,
    description: string,
    imageSource: string,
    technologies: Array<string>,
    githubLink: string,
    websiteLink: string,
    projectType: string,
}

function Card(props) {

    let projectData: projectDataModel = props.projectData;

    function openProjectModal(name) {
        props.openProjectModal(name);
    }

    return(

        <div className={projectData.projectType}>
            <div className="project" onClick={() => openProjectModal(projectData.name)}> 
            {/* <div className="project"> */}
                <div className="rounded-t-[10px] overflow-hidden relative">
                    <img className="rounded-t-[10px] max-h-[377px] w-[100%] projectImage" src={projectData.imageSource} />
                    <div className="absolute top-[50%] left-[50%] hidden font-semibold readMoreImage" style={{
                        transform: "translate(-50%, -50%)"
                    }}>
                        Read More ➤
                    </div>
                </div>
            </div>
            <div className="px-[20px] py-[30px] bg-[#06131e] rounded-b-[10px] overflow-hidden flex flex-col h-[400px]">
                <div className="text-[20px] text-left">
                    {projectData.title}
                </div>
                <div className="flex items-center mt-[20px] gap-[10px] flex-wrap text-[12px]">
                    {projectData.technologies.map((technology, i) => 
                        <span key={technology} className="bg-[#0167dc] text-[#ffffff] rounded-[5px] px-[10px] py-[5px] font-semibold">{technology}</span>
                    )}
                    {
                        projectData.githubLink.length > 0 && 
                        <a className="" href={projectData.githubLink} target="_blank">
                            <div className="w-fit flex items-center projectLink">
                                <svg className="fill-white" width="30px" height="30px" viewBox="0 0 20 20" version="1.1">
                                    <g id="Page-1" stroke="none" stroke-width="1" fill="" fill-rule="evenodd">
                                        <g className="" id="Dribbble-Light-Preview" transform="translate(-140.000000, -7559.000000)" fill="">
                                            <g id="icons" transform="translate(56.000000, 160.000000)">
                                                <path d="M94,7399 C99.523,7399 104,7403.59 104,7409.253 C104,7413.782 101.138,7417.624 97.167,7418.981 C96.66,7419.082 96.48,7418.762 96.48,7418.489 C96.48,7418.151 96.492,7417.047 96.492,7415.675 C96.492,7414.719 96.172,7414.095 95.813,7413.777 C98.04,7413.523 100.38,7412.656 100.38,7408.718 C100.38,7407.598 99.992,7406.684 99.35,7405.966 C99.454,7405.707 99.797,7404.664 99.252,7403.252 C99.252,7403.252 98.414,7402.977 96.505,7404.303 C95.706,7404.076 94.85,7403.962 94,7403.958 C93.15,7403.962 92.295,7404.076 91.497,7404.303 C89.586,7402.977 88.746,7403.252 88.746,7403.252 C88.203,7404.664 88.546,7405.707 88.649,7405.966 C88.01,7406.684 87.619,7407.598 87.619,7408.718 C87.619,7412.646 89.954,7413.526 92.175,7413.785 C91.889,7414.041 91.63,7414.493 91.54,7415.156 C90.97,7415.418 89.522,7415.871 88.63,7414.304 C88.63,7414.304 88.101,7413.319 87.097,7413.247 C87.097,7413.247 86.122,7413.234 87.029,7413.87 C87.029,7413.87 87.684,7414.185 88.139,7415.37 C88.139,7415.37 88.726,7417.2 91.508,7416.58 C91.513,7417.437 91.522,7418.245 91.522,7418.489 C91.522,7418.76 91.338,7419.077 90.839,7418.982 C86.865,7417.627 84,7413.783 84,7409.253 C84,7403.59 88.478,7399 94,7399" id="github-[#142]">
                                                </path>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                        </a>
                    }
                    {
                        projectData.websiteLink.length > 0 && 
                        <a className="" href={projectData.websiteLink} target="_blank">
                            <div className="w-fit flex items-center projectLink">
                                <svg width="25px" height="25px" viewBox="0 0 16 16" fill="white" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.05025 1.53553C8.03344 0.552348 9.36692 0 10.7574 0C13.6528 0 16 2.34721 16 5.24264C16 6.63308 15.4477 7.96656 14.4645 8.94975L12.4142 11L11 9.58579L13.0503 7.53553C13.6584 6.92742 14 6.10264 14 5.24264C14 3.45178 12.5482 2 10.7574 2C9.89736 2 9.07258 2.34163 8.46447 2.94975L6.41421 5L5 3.58579L7.05025 1.53553Z" fill="#ffffff"/>
                                    <path d="M7.53553 13.0503L9.58579 11L11 12.4142L8.94975 14.4645C7.96656 15.4477 6.63308 16 5.24264 16C2.34721 16 0 13.6528 0 10.7574C0 9.36693 0.552347 8.03344 1.53553 7.05025L3.58579 5L5 6.41421L2.94975 8.46447C2.34163 9.07258 2 9.89736 2 10.7574C2 12.5482 3.45178 14 5.24264 14C6.10264 14 6.92742 13.6584 7.53553 13.0503Z" fill="#ffffff"/>
                                    <path d="M5.70711 11.7071L11.7071 5.70711L10.2929 4.29289L4.29289 10.2929L5.70711 11.7071Z" fill="#ffffff"/>
                                </svg>
                            </div>
                        </a>
                    }
                </div>
                <div className="mt-[20px] overflow-hidden leading-[33px]">
                    {projectData.description}
                </div>
                <div className="text-[#60a7f9] cursor-pointer w-fit flex items-center pt-[10px]" onClick={() => openProjectModal(projectData.name)}> 
                    Read More
                    <svg width="15px" height="15px" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" fill="#000000" stroke="#000000">
                        <g id="SVGRepo_bgCarrier" stroke-width="2"/>
                        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>
                        <g id="SVGRepo_iconCarrier">
                        <path d="M256 120.768L306.432 64 768 512l-461.568 448L256 903.232 659.072 512z" fill="#60a7f9"/>
                        </g>
                    </svg>
                    <svg className="ml-[-10px]" width="15px" height="15px" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" fill="#000000" stroke="#000000">
                        <g id="SVGRepo_bgCarrier" stroke-width="2"/>
                        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>
                        <g id="SVGRepo_iconCarrier">
                        <path d="M256 120.768L306.432 64 768 512l-461.568 448L256 903.232 659.072 512z" fill="#60a7f9"/>
                        </g>
                    </svg>
                </div>
            </div>
        </div>

    )
}

export default Card;